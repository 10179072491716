import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import styled from 'styled-components'
import { Button, IconButton, Switch, Tooltip } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { InputForm } from '../../../../components/modules/InputForm'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { useAddAlert } from './hook'
import { OptionSelectStatus, OptionSelectType } from './contants'
import { AddCategoryInclude } from './AddCategoryInclude'
import { AddCategoryExclude } from './AddCategoryExclude'

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`

const StyledTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: flex-end;
`
const StyledSelectForm = styled(SelectForm)`
  max-width: 300px;
`

const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`

const StyledWrapperContent = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`
/**
 * @returns Component Add Alert Screen
 */
export const AddAlertScreen = () => {
  const {
    handleChangeInput,
    formValue,
    errors,
    handleSubmit,
    handleOnChangeSelect,
    handleToggleRoutine,
    isShowInput,
    setIsShowInput,
    handleAddIncludeCategory,
    handleAddExcludeCategory,
    handleRemoveBox,
    handleRemoveBoxExclude,
  } = useAddAlert()

  return (
    <StyledContainer>
      <StyledTitle>Add Alert</StyledTitle>
      <StyledInputForm
        isRequired
        direction={'horizontal'}
        error={errors?.attributes?.message}
        isInvalid={!!errors?.attributes?.message}
        label={'Attributes'}
        labelWidth={60}
        placeholder={''}
        value={formValue?.attributes}
        onChange={e => handleChangeInput('attributes', e.target.value)}
      />
      <StyledInputForm
        isRequired
        direction={'horizontal'}
        error={errors?.tag?.message}
        isInvalid={!!errors?.tag?.message}
        label={'Tag'}
        labelWidth={60}
        placeholder={''}
        value={formValue?.tag}
        onChange={e => handleChangeInput('tag', e.target.value)}
      />
      <StyledInputForm
        isRequired
        direction={'horizontal'}
        error={errors?.buttonText?.message}
        isInvalid={!!errors?.buttonText?.message}
        label={'Text Button'}
        labelWidth={60}
        placeholder={''}
        value={formValue?.buttonText}
        onChange={e => handleChangeInput('buttonText', e.target.value)}
      />
      <StyledInputForm
        isRequired
        direction={'horizontal'}
        error={errors?.alert?.message}
        isInvalid={!!errors?.alert?.message}
        label={'Alert'}
        labelWidth={60}
        placeholder={''}
        value={formValue?.alert}
        onChange={e => handleChangeInput('alert', e.target.value)}
      />
      <StyledInputForm
        isRequired
        direction={'horizontal'}
        error={errors?.missAlert?.message}
        isInvalid={!!errors?.missAlert?.message}
        label={'Miss Alert'}
        labelWidth={60}
        placeholder={''}
        value={formValue?.missAlert}
        onChange={e => handleChangeInput('missAlert', e.target.value)}
      />
      <Flex flexDirection={'row'}>
        <StyledSelectForm
          direction={'horizontal'}
          label={'Type'}
          labelWidth={60}
          name={'type'}
          options={OptionSelectType}
          placeholder={''}
          value={
            formValue.type?.value === ''
              ? OptionSelectType[1]
              : OptionSelectType.find(it => it.value === formValue.type?.value)
          }
          onChange={option => handleOnChangeSelect('type', option)}
        />
      </Flex>
      <Flex flexDirection={'row'}>
        <StyledSelectForm
          direction={'horizontal'}
          label={'Status'}
          labelWidth={60}
          name={'status'}
          options={OptionSelectStatus}
          placeholder={''}
          value={
            formValue.status?.value === ''
              ? OptionSelectStatus[0]
              : OptionSelectStatus.find(
                  it => it.value === formValue.status?.value,
                )
          }
          onChange={option => handleOnChangeSelect('status', option)}
        />
      </Flex>
      <Flex alignItems={'center'} gap={'16px'}>
        <TextPromotion>Routine</TextPromotion>
        <Switch
          isChecked={formValue.routine}
          size="md"
          onChange={handleToggleRoutine}
        />
      </Flex>
      <Box background={'black'} h={'1px'} w={'full'} />

      <Flex>
        <Flex
          borderRightColor={'black'}
          borderRightWidth={formValue.status.label === 'RED' ? '1px' : '0px'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          w={'100%'}
        >
          {formValue.categoriesInclude?.map((e, i) => (
            <Flex key={i} w={'50%'}>
              <Flex flexDirection={'column'} gap={'20px'} w={'50%'}>
                <Flex color={'green'}>INCLUDE_LIST</Flex>
                <AddCategoryInclude
                  category={e}
                  onChange={value =>
                    handleOnChangeSelect(
                      'categoriesInclude',
                      formValue.categoriesInclude?.map((e, ii) =>
                        ii === i ? value : e,
                      ),
                    )
                  }
                />
                <StyledWrapperContent>
                  <Box>
                    <Tooltip
                      bg={'#000000'}
                      label={`Add Include Category`}
                      placement="top-start"
                    >
                      <IconButton
                        aria-label="button flexible"
                        icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                        onClick={
                          isShowInput
                            ? handleAddIncludeCategory
                            : () => setIsShowInput(true)
                        }
                      />
                    </Tooltip>
                  </Box>
                  {i !== 0 && (
                    <Box>
                      <Tooltip
                        bg={'#000000'}
                        label={`Remove Include Category`}
                        placement="top-start"
                      >
                        <IconButton
                          aria-label="button flexible"
                          icon={<MinusIcon />}
                          onClick={() => handleRemoveBox(i)}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </StyledWrapperContent>
              </Flex>
            </Flex>
          ))}
        </Flex>
        {formValue.status.label === 'RED' ? (
          <Flex
            flexDirection={'column'}
            justifyContent={'flex-start'}
            w={'100%'}
          >
            {formValue.categoriesExclude?.map((e, i) => (
              <Flex key={i} w={'50%'}>
                <Flex
                  flexDirection={'column'}
                  gap={'20px'}
                  justifyContent={'flex-start'}
                  pl={'30px'}
                  w={'50%'}
                >
                  <Flex color={'#C53030'}>EXCLUDE_LIST</Flex>
                  <AddCategoryExclude
                    category={e}
                    onChange={value =>
                      handleOnChangeSelect(
                        'categoriesExclude',
                        formValue.categoriesExclude?.map((e, ii) =>
                          ii === i ? value : e,
                        ),
                      )
                    }
                  />
                  <StyledWrapperContent>
                    <Box>
                      <Tooltip
                        bg={'#000000'}
                        label={`Add Include Category`}
                        placement="top-start"
                      >
                        <IconButton
                          aria-label="button flexible"
                          icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                          onClick={
                            isShowInput
                              ? handleAddExcludeCategory
                              : () => setIsShowInput(true)
                          }
                        />
                      </Tooltip>
                    </Box>
                    {i !== 0 && (
                      <Box>
                        <Tooltip
                          bg={'#000000'}
                          label={`Remove Include Category`}
                          placement="top-start"
                        >
                          <IconButton
                            aria-label="button flexible"
                            icon={<MinusIcon />}
                            onClick={() => handleRemoveBoxExclude(i)}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </StyledWrapperContent>
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <></>
        )}
      </Flex>

      <StyledWrapperButton>
        <Button
          _hover={{ background: '#000000' }}
          background={'#000000'}
          color={'#fff'}
          w={'180px'}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </StyledWrapperButton>
    </StyledContainer>
  )
}
