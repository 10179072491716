import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import styled from 'styled-components'
import { Button, IconButton, Switch, Tooltip } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { InputForm } from '../../../../components/modules/InputForm'
import { AddCategoryExclude } from '../../../AddAlertPage/components/module/AddCategoryExclude'
import {
  OptionSelectStatus,
  OptionSelectType,
} from '../../../AddAlertPage/components/module/contants'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { useDetailAlert } from './hook'
import { CategoryInclude } from './CategoryInclude'

/**
 * @returns Component Detail Alert Screen
 */
export const DetailAlertScreen = () => {
  const {
    handleChangeInput,
    errors,
    alertDetail,
    handleCancel,
    handleSubmit,
    isShowInput,
    setIsShowInput,
    handleAddIncludeCategory,
    handleOnChangeSelect,
    handleRemoveBox,
    handleAddExcludeCategory,
    handleRemoveBoxExclude,
    handleToggleRoutine,
  } = useDetailAlert()
  console.log(
    typeof String(alertDetail.status as Record<string, string>),
    'alertDetail.status',
  )
  return (
    <>
      <StyledWrapperContent>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.possibleAttributes?.message}
          isInvalid={!!errors?.possibleAttributes?.message}
          label={'Attributes'}
          labelWidth={60}
          placeholder={''}
          value={alertDetail?.possibleAttributes}
          onChange={e =>
            handleChangeInput('possibleAttributes', e.target.value)
          }
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.tag?.message}
          isInvalid={!!errors?.tag?.message}
          label={'Tag'}
          labelWidth={60}
          placeholder={''}
          value={alertDetail?.tag}
          onChange={e => handleChangeInput('tag', e.target.value)}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.buttonText?.message}
          isInvalid={!!errors?.buttonText?.message}
          label={'Text Button'}
          labelWidth={60}
          placeholder={''}
          value={alertDetail?.buttonText}
          onChange={e => handleChangeInput('buttonText', e.target.value)}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.alert?.message}
          isInvalid={!!errors?.alert?.message}
          label={'Alert'}
          labelWidth={60}
          placeholder={''}
          value={alertDetail?.alert}
          onChange={e => handleChangeInput('alert', e.target.value)}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.missAlert?.message}
          isInvalid={!!errors?.missAlert?.message}
          label={'Miss Alert'}
          labelWidth={60}
          placeholder={''}
          value={alertDetail?.missAlert}
          onChange={e => handleChangeInput('missAlert', e.target.value)}
        />

        <Flex flexDirection={'row'}>
          <StyledSelectForm
            direction={'horizontal'}
            label={'Type'}
            labelWidth={60}
            name={'type'}
            options={OptionSelectType}
            placeholder={''}
            value={
              typeof alertDetail.type === 'object'
                ? alertDetail.type
                : OptionSelectType.find(
                    it => it.value === String(alertDetail.type),
                  )
            }
            onChange={option => handleOnChangeSelect('type', option)}
          />
        </Flex>
        <Flex flexDirection={'row'}>
          <StyledSelectForm
            direction={'horizontal'}
            label={'Status'}
            labelWidth={60}
            name={'status'}
            options={OptionSelectStatus}
            placeholder={''}
            value={
              typeof alertDetail.status === 'object'
                ? alertDetail.status
                : OptionSelectStatus.find(
                    it => it.value === String(alertDetail.status),
                  )
            }
            onChange={option => handleOnChangeSelect('status', option)}
          />
        </Flex>
        <Flex alignItems={'center'} gap={'16px'}>
          <TextPromotion>Routine</TextPromotion>
          <Switch
            isChecked={alertDetail.routine}
            size="md"
            onChange={handleToggleRoutine}
          />
        </Flex>
        <Box background={'black'} h={'1px'} w={'full'} />

        <Flex>
          <Flex
            borderRightColor={'black'}
            borderRightWidth={
              alertDetail.status === 1 ||
              Number(
                (alertDetail.status as Record<string, string>)?.value === '1',
              )
                ? '1px'
                : '0px'
            }
            flexDirection={'column'}
            gap={'20px'}
            justifyContent={'flex-start'}
            w={'100%'}
          >
            {alertDetail?.categoriesInclude?.map((e, i) => (
              <Flex key={i} w={'50%'}>
                <Flex flexDirection={'column'} gap={'20px'} w={'50%'}>
                  <Flex color={'green'}>INCLUDE_LIST</Flex>
                  <CategoryInclude
                    category={e}
                    onChange={value =>
                      handleOnChangeSelect(
                        'categoriesInclude',
                        alertDetail.categoriesInclude?.map((e, ii) =>
                          ii === i ? value : e,
                        ),
                      )
                    }
                  />
                  <StyledWrapperContent>
                    <Box>
                      <Tooltip
                        bg={'#000000'}
                        label={`Add Include Category`}
                        placement="top-start"
                      >
                        <IconButton
                          aria-label="button flexible"
                          icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                          onClick={
                            isShowInput
                              ? handleAddIncludeCategory
                              : () => setIsShowInput(true)
                          }
                        />
                      </Tooltip>
                    </Box>
                    {i !== 0 && (
                      <Box>
                        <Tooltip
                          bg={'#000000'}
                          label={`Remove Include Category`}
                          placement="top-start"
                        >
                          <IconButton
                            aria-label="button flexible"
                            icon={<MinusIcon />}
                            onClick={() => handleRemoveBox(i)}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </StyledWrapperContent>
                </Flex>
              </Flex>
            ))}
          </Flex>
          {String(
            (alertDetail.status as Record<string, string>)?.value === '1',
          ) && (
            <Flex
              flexDirection={'column'}
              justifyContent={'flex-start'}
              w={'100%'}
            >
              {alertDetail?.categoriesExclude?.map((e, i) => (
                <Flex key={i} w={'50%'}>
                  <Flex
                    flexDirection={'column'}
                    gap={'20px'}
                    justifyContent={'flex-start'}
                    pl={'30px'}
                    w={'50%'}
                  >
                    <Flex color={'#C53030'}>EXCLUDE_LIST</Flex>
                    <AddCategoryExclude
                      category={e}
                      onChange={value =>
                        handleOnChangeSelect(
                          'categoriesExclude',
                          alertDetail?.categoriesExclude?.map((e, ii) =>
                            ii === i ? value : e,
                          ),
                        )
                      }
                    />
                    <StyledWrapperContent>
                      <Box>
                        <Tooltip
                          bg={'#000000'}
                          label={`Add Include Category`}
                          placement="top-start"
                        >
                          <IconButton
                            aria-label="button flexible"
                            icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                            onClick={
                              isShowInput
                                ? handleAddExcludeCategory
                                : () => setIsShowInput(true)
                            }
                          />
                        </Tooltip>
                      </Box>
                      {i !== 0 && (
                        <Box>
                          <Tooltip
                            bg={'#000000'}
                            label={`Remove Include Category`}
                            placement="top-start"
                          >
                            <IconButton
                              aria-label="button flexible"
                              icon={<MinusIcon />}
                              onClick={() => handleRemoveBoxExclude(i)}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </StyledWrapperContent>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
        <StyledWrapperButton>
          <StyledButtonOutline onClick={handleCancel}>
            Cancel
          </StyledButtonOutline>
          <StyledButtonSolid onClick={handleSubmit}>Save</StyledButtonSolid>
        </StyledWrapperButton>
      </StyledWrapperContent>
    </>
  )
}

const StyledWrapperContent = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`
const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  &:hover {
    background: #fff;
  }
`
const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`
const StyledSelectForm = styled(SelectForm)`
  max-width: 300px;
`
const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`
