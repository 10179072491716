import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import {
  exportFileRoutineLog,
  getIndividualUser,
  getTotalUserRoutine,
} from '../../../../../service/authService'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { TIndividualUser, TRoutine } from './type'
import { Option, OptionSelect } from './constants'

/**
 * @returns Component useIndividualUser hook
 */
export const useIndividualUser = () => {
  const [dataIndividualUser, setDataIndividualUser] =
    useState<TIndividualUser[]>()
  const [dataIndividualSingerUser, setDataIndividualSingerUser] =
    useState<TIndividualUser[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const type = searchParams.get('type')
  const name = searchParams.get('name')
  const email = searchParams.get('email')
  const alert = searchParams.get('alert')
  const typeAttributes = searchParams.getAll('typeAttributes')
  const [valueInputName, setValueInputName] = useState(name || '')
  const [valueInputEmail, setValueInputEmail] = useState(email || '')
  const [valueInputAlert, setValueInputAlert] = useState(alert || '')
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const navigate = useNavigate()
  const [isButtonChosen, setIsButtonChosen] = useState<number>()
  const [isOpenModalExportData, setIsOpenModalExportData] =
    useState<boolean>(false)
  const [downloadedItem, setDownloadedItem] = useState<number[]>([])
  const [valueSelect, setValueSelect] = useState<Option>(
    OptionSelect.find(item => item.value === type) ?? OptionSelect[0],
  )
  const [valueSelectAttributes, setValueSelectAttributes] = useState<{
    label: string
    value: number
  } | null>(null)
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])
  const [numberIndexTab, setNumberIndexTab] = useState<number>()
  const [dataRoutine, setDataRoutine] = useState<TRoutine>()
  const handleChangeSelect = useCallback(
    value => {
      if (value === valueSelect) return
      setSearchParams({
        page: '1',
        type: value.value ?? OptionSelect[0].value,
      })
      setValueSelect(value)
      setValueStartAt(undefined)
      setValueEndAt(undefined)
      setDownloadedItem([])
      setTextValidate('')
    },
    [setSearchParams, valueSelect],
  )
  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const fetchDataIndividualUser = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
        name: name ? name : undefined,
        email: email ? email : undefined,
        alert: alert ? alert : undefined,
        typeAttributes: selectedLabels ? selectedLabels : [],
      }
      const response = await getIndividualUser(
        params,
        type ?? OptionSelect[0].value,
      )
      setDataIndividualUser(response.data)
      const newData = response.data.reduce((acc, curr) => {
        const indexSameUser = acc.findIndex(it => it.userId === curr.userId)
        if (indexSameUser === -1) {
          acc.push(curr)
        } else {
          const newItem = acc[indexSameUser]
          const currItemTime = moment(curr.createdAt).format(
            'YYYY/MM/DD, HH:mm:ss A',
          )
          const accTtemTime = moment(newItem.time).format(
            'YYYY/MM/DD, HH:mm:ss A',
          )
          if (currItemTime > accTtemTime) {
            acc[indexSameUser] = curr
          }
        }
        return acc
      }, [])
      setDataIndividualSingerUser(newData)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [startAt, endAt, page, name, email, selectedLabels, alert, type, toast])

  useEffect(() => {
    fetchDataIndividualUser().then()
  }, [
    email,
    name,
    alert,
    startAt,
    endAt,
    JSON.stringify(typeAttributes),
    fetchDataIndividualUser,
  ])

  const fetchUserRoutine = useCallback(async () => {
    setIsLoading(true)
    try {
      const infoUserRoutine = await getTotalUserRoutine()
      setDataRoutine(infoUserRoutine)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [toast])

  useEffect(() => {
    fetchUserRoutine().then()
  }, [fetchUserRoutine])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        name: String(valueInputName),
        email: String(valueInputEmail),
        alert: String(valueInputAlert),
        page: String(currentPage),
        typeAttributes: selectedLabels,
        startAt: valueStartAt ? moment(valueStartAt).format('YYYY/MM/DD') : '',
        endAt: valueEndAt ? moment(valueEndAt).format('YYYY/MM/DD') : '',
        type: type ?? OptionSelect[0].value,
      })
    },
    [
      selectedLabels,
      setSearchParams,
      type,
      valueEndAt,
      valueInputAlert,
      valueInputEmail,
      valueInputName,
      valueStartAt,
    ],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])
  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          type: type ?? OptionSelect[0].value,
          name: String(valueInputName),
          email: String(valueInputEmail),
          alert: String(valueInputAlert),
          typeAttributes: selectedLabels,
          page: String(1),
          startAt: valueStartAt
            ? moment(valueStartAt).format('YYYY/MM/DD')
            : '',
          endAt: valueEndAt ? moment(valueEndAt).format('YYYY/MM/DD') : '',
        })
        setDownloadedItem([])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [
    validateDate,
    setSearchParams,
    type,
    valueInputName,
    valueInputEmail,
    valueInputAlert,
    selectedLabels,
    valueStartAt,
    valueEndAt,
    toast,
    name,
    email,
    alert,
  ])
  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setValueInputName('')
    setValueInputEmail('')
    setValueInputAlert('')
    setValueSelectAttributes(null)
    setSelectedLabels([])
    setSearchParams({
      page: '1',
      type: type ?? OptionSelect[0].value,
    })
  }, [setSearchParams, type])

  const handleExportToXLSX = useCallback(() => {
    setIsOpenModalExportData(true)
  }, [])

  const handleClickDownload = useCallback(
    async (page: number) => {
      setIsButtonChosen(page)
      try {
        const params = {
          page: page,
          limit: 5000,
          isExport: true,
          isApplyFilterExport: true,
          startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
          endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        }
        const example = await exportFileRoutineLog(
          params,
          type ?? OptionSelect[0].value,
        )
        const link = document.createElement('a')
        link.href = example
        document.body.appendChild(link)
        link.click()
        setIsButtonChosen(0)
        setDownloadedItem(prevState => [...prevState, page])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
        setIsButtonChosen(0)
      }
    },
    [endAt, startAt, toast, type],
  )

  const handleChangeTab = useCallback(
    index => {
      setNumberIndexTab(index)
      setValueStartAt(undefined)
      setValueEndAt(undefined)
      setTextValidate('')
      setValueInputName('')
      setValueInputEmail('')
      setValueInputAlert('')
      setValueSelectAttributes(null)
      setSearchParams({
        page: '1',
        type: type ?? OptionSelect[0].value,
      })
    },
    [setSearchParams, type],
  )

  const handleChangeInputName = useCallback(e => {
    setValueInputName(e.target.value)
  }, [])

  const handleChangeInputEmail = useCallback(e => {
    setValueInputEmail(e.target.value)
  }, [])

  const handleChangeInputAlert = useCallback(e => {
    setValueInputAlert(e.target.value)
  }, [])

  const handleChangeSelectAttributes = useCallback(value => {
    setValueSelectAttributes(value)
    const labelsArray = value.map(option => option?.label)
    setSelectedLabels(labelsArray)
  }, [])

  return {
    dataIndividualUser,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    totalItem: meta?.total || 0,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    navigate,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    isButtonChosen,
    handleClickDownload,
    setIsOpenModalExportData,
    valueSelect,
    handleChangeSelect,
    type,
    dataIndividualSingerUser,
    numberIndexTab,
    handleChangeTab,
    handleChangeInputName,
    valueInputName,
    handleChangeInputAlert,
    valueInputAlert,
    handleChangeSelectAttributes,
    valueSelectAttributes,
    handleChangeInputEmail,
    valueInputEmail,
    dataRoutine,
  }
}
