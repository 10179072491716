import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import {
  TCCategory1,
  TCCategory2,
  TCCategory3,
} from '../../../ViewProductData/components/modules/types'
import {
  getCategory1,
  getCategory2,
  getCategory3,
} from '../../../../../service/productService'

export const useAddCategory = () => {
  const [categoryInclude1, setCategoryInclude1] = useState<TCCategory1[]>()
  const [categoryInclude2, setCategoryInclude2] = useState<TCCategory2[]>()
  const [categoryInclude3, setCategoryInclude3] = useState<TCCategory3[]>()
  const [categoryExclude1, setCategoryExclude1] = useState<TCCategory1[]>()
  const [categoryExclude2, setCategoryExclude2] = useState<TCCategory2[]>()
  const [categoryExclude3, setCategoryExclude3] = useState<TCCategory2[]>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const fetchCategoryInclude1 = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getCategory1()
      const mockCategory1 = options.map(it => {
        return {
          id: it.id,
          value: it.content,
          label: it.content,
        }
      })
      setCategoryInclude1(mockCategory1)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])
  useEffect(() => {
    fetchCategoryInclude1()
  }, [fetchCategoryInclude1])

  const fetchCategoryInclude2 = useCallback(
    async (catId1: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory2(catId1)
        const mockCategory2 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategoryInclude2(mockCategory2)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    fetchCategoryInclude2(1)
  }, [fetchCategoryInclude2])

  const fetchCategoryInclude3 = useCallback(
    async (catId2: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory3(catId2)
        const mockCategory3 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategoryInclude3(mockCategory3)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    fetchCategoryInclude3(1)
  }, [fetchCategoryInclude3])

  const fetchCategoryExclude1 = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getCategory1()
      const mockCategory1 = options.map(it => {
        return {
          id: it.id,
          value: it.content,
          label: it.content,
        }
      })
      setCategoryExclude1(mockCategory1)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])
  useEffect(() => {
    fetchCategoryExclude1()
  }, [fetchCategoryExclude1])

  const fetchCategoryExclude2 = useCallback(
    async (catId1: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory2(catId1)
        const mockCategory2 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategoryExclude2(mockCategory2)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    fetchCategoryExclude2(1)
  }, [fetchCategoryExclude2])

  const fetchCategoryExclude3 = useCallback(
    async (catId2: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory3(catId2)
        const mockCategory3 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategoryExclude3(mockCategory3)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    fetchCategoryExclude3(1)
  }, [fetchCategoryExclude3])

  return {
    isLoading,
    categoryInclude1,
    categoryInclude2,
    categoryInclude3,
    categoryExclude1,
    categoryExclude2,
    categoryExclude3,
    mutateIn1: fetchCategoryInclude1,
    mutateIn2: fetchCategoryInclude2,
    mutateIn3: fetchCategoryInclude3,
    mutateEx2: fetchCategoryExclude2,
    mutateEx3: fetchCategoryExclude3,
  }
}
