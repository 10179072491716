import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { useAddCategory } from '../hooks/addCategory.hook'

const StyledSelectForm = styled(SelectForm)`
  max-width: 100px;
`

type DefaultCategory = {
  catInclude1: { id?: string; label?: string; value?: string }
  catInclude2: { id?: string; label?: string; value?: string }
  catInclude3: { id?: string; label?: string; value?: string }
}

type Props<T extends DefaultCategory> = {
  category: T
  onChange?: (value: T) => void
}

/**
 * @returns Component Add Category
 */
export const AddCategoryInclude = <T extends DefaultCategory>({
  category,
  onChange,
}: Props<T>) => {
  const onSelect = useCallback(
    (value: T) => {
      onChange?.(value)
    },
    [onChange],
  )
  const {
    categoryInclude1,
    categoryInclude2,
    categoryInclude3,
    mutateIn2,
    mutateIn3,
    isLoading,
  } = useAddCategory()

  return (
    <React.Fragment>
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        isLoading={isLoading}
        label={'Category1'}
        options={categoryInclude1}
        placeholder={''}
        value={category?.catInclude1}
        onChange={option => {
          onSelect({ ...category, catInclude1: option })
          // @ts-ignore
          mutateIn2((option as object).id)
        }}
      />
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        isLoading={isLoading}
        label={'Category2'}
        options={category.catInclude1?.id ? categoryInclude2 : []}
        placeholder={''}
        value={category?.catInclude2}
        onChange={option => {
          onSelect({ ...category, catInclude2: option })
          // @ts-ignore
          mutateIn3((option as object).id)
        }}
      />
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        isLoading={isLoading}
        label={'Category3'}
        options={category.catInclude2?.id ? categoryInclude3 : []}
        placeholder={''}
        value={category?.catInclude3}
        onChange={option => onSelect({ ...category, catInclude3: option })}
      />
    </React.Fragment>
  )
}
