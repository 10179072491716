import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { useAddCategory } from '../hooks/addCategory.hook'

const StyledSelectForm = styled(SelectForm)`
  max-width: 100px;
`

type DefaultCategory = {
  catExclude1: { id?: string; label?: string; value?: string }
  catExclude2: { id?: string; label?: string; value?: string }
  catExclude3: { id?: string; label?: string; value?: string }
}

type Props<T extends DefaultCategory> = {
  category: T
  onChange?: (value: T) => void
}

/**
 * @returns Component Add Category
 */
export const AddCategoryExclude = <T extends DefaultCategory>({
  category,
  onChange,
}: Props<T>) => {
  const onSelect = useCallback(
    (value: T) => {
      onChange?.(value)
    },
    [onChange],
  )
  const {
    categoryExclude1,
    categoryExclude2,
    categoryExclude3,
    mutateEx2,
    mutateEx3,
  } = useAddCategory()

  return (
    <React.Fragment>
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        label={'Category1'}
        options={categoryExclude1}
        placeholder={''}
        value={category?.catExclude1}
        onChange={option => {
          onSelect({ ...category, catExclude1: option })
          // @ts-ignore
          mutateEx2((option as object).id)
        }}
      />
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        label={'Category2'}
        options={category.catExclude1?.id ? categoryExclude2 : []}
        placeholder={''}
        value={category?.catExclude2}
        onChange={option => {
          onSelect({ ...category, catExclude2: option })
          // @ts-ignore
          mutateEx3((option as object).id)
        }}
      />
      <StyledSelectForm
        isRequired
        direction={'horizontal'}
        label={'Category3'}
        options={category.catExclude2?.id ? categoryExclude3 : []}
        placeholder={''}
        value={category?.catExclude3}
        onChange={option => onSelect({ ...category, catExclude3: option })}
      />
    </React.Fragment>
  )
}
