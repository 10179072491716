import { useCallback, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Option } from '../../../../../types'
import { createAlert } from '../../../../../service/viewRules'

const schema = yup.object().shape({
  attributes: yup.string().required('Attributes is a required field'),
  tag: yup.string().required('Tag is a required field'),
  buttonText: yup.string().required('Text Button is a required field'),
  alert: yup.string().required('Alert is a required field'),
  missAlert: yup.string().required('Miss Alert is a required field'),
  type: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  status: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  routine: yup.boolean(),
  categoriesInclude: yup.array().of(
    yup.object().shape({
      catInclude1: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
      catInclude2: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
      catInclude3: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
    }),
  ),
  categoriesExclude: yup.array().of(
    yup.object().shape({
      catExclude1: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
      catExclude2: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
      catExclude3: yup.object().shape({
        id: yup.string(),
        value: yup.string(),
        label: yup.string(),
      }),
    }),
  ),
})

type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  attributes: '',
  tag: '',
  buttonText: '',
  alert: '',
  missAlert: '',
  type: { value: '', label: '' },
  status: { value: '', label: '' },
  routine: false,
  categoriesInclude: [
    {
      catInclude1: { id: '', value: '', label: '' },
      catInclude2: { id: '', value: '', label: '' },
      catInclude3: { id: '', value: '', label: '' },
    },
  ],
  categoriesExclude: [
    {
      catExclude1: { id: '', value: '', label: '' },
      catExclude2: { id: '', value: '', label: '' },
      catExclude3: { id: '', value: '', label: '' },
    },
  ],
}
export type Errors = {
  [key: string]: {
    message: string
  }
}

export type TPayload = {
  tag: string
  alert: string
  missAlert: string
  buttonText: string
  possibleAttributes: string
  type: number
  status: number
  routine: boolean
  include?: {
    cat1: string | null
    cat2: string | null
    cat3: string | null
  }[]
  excepts?: {
    cat1: string | null
    cat2: string | null
    cat3: string | null
  }[]
}

export const useAddAlert = () => {
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const [isShowInput, setIsShowInput] = useState(true)

  const [errors, setErrors] = useState<Errors>()
  const toast = useToast()
  const navigate = useNavigate()

  const handleChangeInput = useCallback((name: string, value?: string) => {
    setFormValue(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setErrors(prevState => ({
      ...prevState,
      [name]: { message: '' },
    }))
  }, [])

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }
    return true
  }, [formValue])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      try {
        const payload: TPayload = {
          tag: formValue.tag,
          alert: formValue.alert,
          missAlert: formValue.missAlert,
          buttonText: formValue.buttonText,
          possibleAttributes: formValue.attributes,
          type: Number(formValue.type.value),
          status: Number(formValue.status.value),
          routine: !!formValue.routine,
          include: formValue.categoriesInclude?.map(item => ({
            cat1: item.catInclude1.value || null,
            cat2: item.catInclude2.value || null,
            cat3: item.catInclude3.value || null,
          })),
        }
        if (
          formValue.categoriesExclude &&
          Number(formValue.status.value) === 1
        ) {
          payload.excepts = formValue.categoriesExclude.map(item => ({
            cat1: item.catExclude1.value || null,
            cat2: item.catExclude2.value || null,
            cat3: item.catExclude3.value || null,
          }))
        }
        await createAlert(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 3000,
        })
        navigate('/list-alert')
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [
    formValue.alert,
    formValue.attributes,
    formValue.buttonText,
    formValue.categoriesExclude,
    formValue.categoriesInclude,
    formValue.missAlert,
    formValue.routine,
    formValue.status.value,
    formValue.tag,
    formValue.type.value,
    navigate,
    toast,
    validation,
  ])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (name: string, option: Option | unknown, indexItem?: number) => {
      if (typeof indexItem === 'number') {
        let currentValue = formValue[name].map((item, index) => {
          if (index === indexItem) {
            return option
          }
          return item
        })
        setFormValue(prevState => ({
          ...prevState,
          [name]: currentValue,
        }))
        return
      }
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [formValue],
  )

  const handleToggleRoutine = useCallback(() => {
    setFormValue({
      ...formValue,
      routine: !formValue.routine,
    })
  }, [formValue])

  const handleAddIncludeCategory = useCallback(() => {
    setFormValue(pre => ({
      ...pre,
      categoriesInclude: [
        ...(pre.categoriesInclude ?? []),
        {
          catInclude1: { id: '', value: '', label: '' },
          catInclude2: { id: '', value: '', label: '' },
          catInclude3: { id: '', value: '', label: '' },
        },
      ],
    }))
  }, [])

  const handleAddExcludeCategory = useCallback(() => {
    setFormValue(pre => ({
      ...pre,
      categoriesExclude: [
        ...(pre.categoriesExclude ?? []),
        {
          catExclude1: { id: '', value: '', label: '' },
          catExclude2: { id: '', value: '', label: '' },
          catExclude3: { id: '', value: '', label: '' },
        },
      ],
    }))
  }, [])

  const handleRemoveBox = useCallback(index => {
    setFormValue(prevState => ({
      ...prevState,
      categoriesInclude: prevState.categoriesInclude
        ? prevState.categoriesInclude.filter((_, idx) => idx !== index)
        : [],
    }))
  }, [])

  const handleRemoveBoxExclude = useCallback(index => {
    setFormValue(prevState => ({
      ...prevState,
      categoriesExclude: prevState.categoriesExclude
        ? prevState.categoriesExclude.filter((_, idx) => idx !== index)
        : [],
    }))
  }, [])

  return {
    handleChangeInput,
    formValue,
    errors,
    handleSubmit,
    handleOnChangeSelect,
    handleToggleRoutine,
    isShowInput,
    setIsShowInput,
    handleAddIncludeCategory,
    handleRemoveBox,
    handleAddExcludeCategory,
    handleRemoveBoxExclude,
  }
}
