import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { Table } from '../../../../components/modules/TableCustom/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { ButtonBase } from '../../../../components/elements/ButtonBase'
import { useListViewAlert } from './hook'

export const ListAlertScreen = () => {
  const {
    data,
    currentPage,
    totalPage,
    handleNextPage,
    isLoading,
    isOpen,
    onClose,
    handleDelete,
    onOpenDelete,
    handleEdit,
    handleCreateAdd,
  } = useListViewAlert()

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }
  const columns = [
    {
      title: 'edit',
      render: record => (
        <Box onClick={() => handleEdit(record.id)}>
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'Attributes',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {record?.possibleAttributes}
        </Box>
      ),
    },

    {
      title: 'Contains',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.tag}</Box>
      ),
    },
    {
      title: 'button Text',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.buttonText}</Box>
      ),
    },
    {
      title: 'include',
      render: record => (
        <>
          {record.status === 0 && (
            <Box>
              {record?.include?.map((item, index) => (
                <Box key={index}>
                  {`IF ${item.cat1 || ''} => ${
                    item.cat2 === null ? 'ALL' : item.cat2
                  } => ${item.cat3 === null ? 'ALL ' : item.cat3}`}
                  {index < record.include.length - 1 && <Box pl={4}>OR</Box>}
                </Box>
              ))}
              <Box>
                <Text as="span" color="green">{`contains `}</Text>
                {record.tag}
              </Box>
              <Box>
                <Text as="span" color="green">{`- " ${record.alert} "`}</Text>
              </Box>
              {record.missAlert && (
                <Box>
                  <Flex gap={'4px'}>
                    <Text>{`ELSE`}</Text>
                    <Text color="#C53030">{'EMPTY'}</Text>
                  </Flex>
                  <Text color="#C53030">{` - " ${record.missAlert} "`}</Text>
                </Box>
              )}
            </Box>
          )}
        </>
      ),
    },
    {
      title: 'exclude',
      render: record => (
        <>
          {record.status === 1 && (
            <Box>
              {record?.include?.map((item, index) => (
                <>
                  <Box key={index}>
                    {`IF ${item.cat1} => ${
                      item.cat2 === null ? 'ALL' : item.cat2
                    } => ${item.cat3 === null ? 'ALL ' : item.cat3} ${
                      record?.excepts?.some(i => {
                        return i.cat1 === item.cat1
                      })
                        ? 'except'
                        : ''
                    }`}
                    {record?.excepts
                      ?.filter(i => i.cat1 === item.cat1)
                      ?.map(it => (
                        <Box fontSize={'12px'} pl={'30px'}>{`${
                          it.cat1 || ''
                        } => ${it.cat2 === null ? 'ALL' : it.cat2} => ${
                          it.cat3 === null ? 'ALL ' : it.cat3
                        }`}</Box>
                      ))}
                    {index < record.include.length - 1 && <Box pl={2}>OR</Box>}
                  </Box>
                </>
              ))}
              {record?.include ? (
                <Box>
                  <Text as="span" color="#C53030">{`contain `}</Text>
                  {record.tag}
                  <Text color="#C53030">{` - " ${record.alert} "`}</Text>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          )}
          {!record.excepts && !record.include && (
            <Box pl={2}>
              <Text>IF ANY CATEGORY/PRODUCT</Text>
              <Text as="span" color="#C53030">{`contains `}</Text>
              {record.tag}
              <Text color="#C53030">{` - " ${record.alert} "`}</Text>
            </Box>
          )}
        </>
      ),
    },
    {
      title: 'Entry Date',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: 'Last Updated Date',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.updatedAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: '',
      render: record => (
        <Button
          colorScheme="red"
          rightIcon={<RiDeleteBin6Line />}
          size={'sm'}
          variant="outline"
          onClick={() => onOpenDelete(record.id)}
        >
          Delete
        </Button>
      ),
    },
  ]
  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex gap={'20px'} justifyContent={'space-between'}>
        <ButtonBase
          hasAdd
          _hover={{ bg: '#385187' }}
          title={'Add Alert'}
          onClick={handleCreateAdd}
        />
      </Flex>
      {data && !!data.length && (
        <>
          <Box maxW={'calc(100vw - 351px)'} overflowX={'auto'} w={'100%'}>
            <Table columns={columns} data={data || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {data?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deactivate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to deactivate this alert?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
