import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { deleteAlert, viewAlertList } from '../../../../../service/viewRules'
import { TAlertResponse, TMetaType } from './types'

export const useListViewAlert = () => {
  const [data, setData] = useState<TAlertResponse[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const page = searchParams.get('page')
  const [alertId, setAlertId] = useState<number>()
  const navigate = useNavigate()

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await viewAlertList(params)
      setData(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleDelete = useCallback(async () => {
    try {
      if (!alertId) {
        return
      }
      await deleteAlert(String(alertId))
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onClose()
      fetchData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [alertId, fetchData, onClose, toast])

  const onOpenDelete = useCallback(
    (id: number) => {
      setAlertId(id)
      onOpen()
    },
    [onOpen],
  )

  const handleEdit = useCallback(
    (alertId: number) => {
      navigate(`/detail-alert/${alertId}`)
    },
    [navigate],
  )

  const handleCreateAdd = useCallback(() => {
    navigate('/add-alert')
  }, [navigate])

  return {
    data,
    isLoading,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    totalItem: meta?.total || 0,
    meta,
    handleNextPage,
    handleDelete,
    isOpen,
    onClose,
    onOpenDelete,
    handleEdit,
    navigate,
    handleCreateAdd,
  }
}
