import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text } from '@chakra-ui/layout'
import {
  Button,
  Link,
  Textarea,
  Image,
  Tooltip,
  IconButton,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Props, useAddProductScreen } from '../../hooks/addProduct.hooks'
import { InputForm } from '../../../../components/modules/InputForm'
import { FlexibleOption } from '../elements/FlexibleOption'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { ModalCreateRetailer } from '../../../../components/modules/ModalCreateRetailer'
import { OptionSelect } from './constants'

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`

const StyledTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`

const StyledWrapperContent = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`

const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`

const StyledWrapperButton = styled(Flex)`
  justify-content: flex-end;
`

const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
  &:hover {
    background: #000000;
    cursor: pointer;
  }
`
const StyledSelectForm = styled(SelectForm)`
  max-width: 300px;
`
const TitleDetail = styled(Flex)`
  width: 240px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  font-family: Georgia, Times New Roman, serif;
  height: 40px;
`
const StyledImage = styled(Image)`
  width: 300px;
  height: 150px;
  border-radius: 4px;
`

const TextError = styled(Text)`
  color: #ba1a1a;
  font-size: 12px;
  margin-top: 8px;
`

/**
 * SingleAddLayout component page.
 * @return {JSX.Element} SingleAddLayout component.
 */
export const SingleAddLayout = (props: Props) => {
  const {
    errors,
    formValue,
    handleSubmit,
    handleChangeInput,
    handleOnChangeSelect,
    category1,
    category2,
    category3,
    handleOnChangeSelectCategory1,
    handleOnChangeSelectCategory2,
    handleOnChangeSelectCategory3,
    labels,
    handleChangeInputRetailes,
    isShowInput,
    setIsShowInput,
    handleAddRetailer,
    handleRemoveBox,
    defaultBox,
    isLoading,
    brandOption,
    retailerOption,
    fetchRetailer,
  } = props
  return (
    <StyledContainer>
      <StyledTitle>Product Add</StyledTitle>
      <StyledWrapperContent>
        <FlexibleOption
          isRequired
          error={errors?.brand?.message}
          isLoading={isLoading}
          label={'Brand'}
          name="brand"
          options={brandOption}
          otherValue={formValue?.other_brand}
          placeholder={''}
          value={formValue?.brand?.value}
          onChangeInput={handleChangeInput}
          onChangeSelect={handleOnChangeSelect}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.name?.message}
          isInvalid={!!errors?.name?.message}
          label={'Name'}
          labelWidth={60}
          placeholder={''}
          value={formValue?.name}
          onChange={e => handleChangeInput('name', e.target.value)}
        />
        <StyledSelectForm
          isRequired
          direction={'horizontal'}
          error={errors?.cat1?.message}
          isLoading={isLoading}
          label={'Category 1'}
          labelWidth={60}
          name={'cat1'}
          options={category1}
          placeholder={''}
          value={formValue?.cat1}
          onChange={option => handleOnChangeSelectCategory1('cat1', option)}
        />
        <StyledSelectForm
          isRequired
          direction={'horizontal'}
          error={errors?.cat2?.message}
          isDisabled={!formValue?.cat1?.value}
          isLoading={isLoading}
          label={'Category 2'}
          labelWidth={60}
          name={'cat2'}
          options={category2}
          placeholder={''}
          value={formValue?.cat2}
          onChange={option => handleOnChangeSelectCategory2('cat2', option)}
        />
        <StyledSelectForm
          isRequired
          direction={'horizontal'}
          error={errors?.cat3?.message}
          isDisabled={!formValue?.cat2?.value}
          isLoading={isLoading}
          label={'Category 3'}
          labelWidth={60}
          name={'cat3'}
          options={category3}
          placeholder={''}
          value={formValue?.cat3}
          onChange={option => handleOnChangeSelectCategory3('cat3', option)}
        />
        <StyledInputForm
          direction={'horizontal'}
          label={'Description'}
          labelWidth={60}
          placeholder={''}
          value={formValue?.description}
          onChange={e => handleChangeInput('description', e.target.value)}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.ingredients?.message}
          isInvalid={!!errors?.ingredients?.message}
          label={'Ingredients (comma separated)'}
          labelWidth={60}
          placeholder={''}
          value={formValue?.ingredients}
          onChange={e => handleChangeInput('ingredients', e.target.value)}
        />
        <StyledInputForm
          direction={'horizontal'}
          label={'UPC'}
          labelWidth={60}
          placeholder={''}
          type={'number'}
          value={formValue?.upcNumber}
          onChange={e => handleChangeInput('upcNumber', e.target.value)}
        />
        <Flex flexDirection={'row'}>
          <StyledSelectForm
            direction={'horizontal'}
            label={'Spf'}
            labelWidth={60}
            name={'spf'}
            options={OptionSelect}
            placeholder={''}
            value={
              formValue.spf?.value === ''
                ? OptionSelect[1]
                : OptionSelect.find(it => it.value === formValue.spf?.value)
            }
            onChange={option => handleOnChangeSelect('spf', option)}
          />
        </Flex>

        <Flex alignItems={'center'} gap={2}>
          <TitleDetail>Upload Notes</TitleDetail>
          <Box>
            <Textarea
              border={
                errors?.joblink?.message
                  ? '2px solid #E53E3E'
                  : '1px solid #e4e7ff'
              }
              color={'#565AC0'}
              minH={'40px'}
              name={'joblink'}
              value={formValue?.joblink ?? ''}
              w={'300px'}
              onChange={e => handleChangeInput('joblink', e.target.value)}
            />
            <TextError>{errors?.joblink?.message}</TextError>
          </Box>
        </Flex>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.urlImage?.message}
          isInvalid={!!errors?.urlImage?.message}
          label={'Image URL'}
          labelWidth={60}
          placeholder={''}
          value={formValue?.urlImage}
          onChange={e => handleChangeInput('urlImage', e.target.value)}
        />
      </StyledWrapperContent>
      <ModalCreateRetailer fetchRetailer={fetchRetailer} />
      <Box>
        {formValue?.urlImage && (
          <Link isExternal href={formValue?.urlImage} maxW={'300px'}>
            <StyledImage
              alt="image product"
              objectFit="contain"
              src={formValue?.urlImage}
            />
          </Link>
        )}
      </Box>
      <Flex
        flexDirection={'column'}
        gap={'12px'}
        justifyContent={'flex-start'}
        pt={'2px'}
        w={'100%'}
      >
        {formValue.originurls.map((e, i) => (
          <React.Fragment key={i}>
            <StyledSelectForm
              key={`select-${i}`}
              isRequired
              direction={'horizontal'}
              error={errors?.[`retailers[${i}]`]?.message}
              isLoading={isLoading}
              label={'Retailer'}
              labelWidth={60}
              options={retailerOption}
              placeholder={''}
              value={formValue?.retailers[i]}
              onChange={option => handleOnChangeSelect(`retailers`, option, i)}
            />
            <StyledWrapperContent>
              {Object.keys(e).map(label => {
                return (
                  <StyledInputForm
                    key={label}
                    direction={'horizontal'}
                    error={errors?.[`originurls[${i}].${label}`]?.message}
                    isInvalid={!!errors?.[`originurls[${i}].${label}`]?.message}
                    isRequired={!['stars', 'reviewCount'].includes(label)}
                    label={labels[label]}
                    labelWidth={60}
                    placeholder={''}
                    type={
                      ['stars', 'reviewCount', 'price'].includes(label)
                        ? 'number'
                        : 'text'
                    }
                    value={e[label] ?? ''}
                    w={'300px'}
                    onChange={event =>
                      handleChangeInputRetailes(i, {
                        ...e,
                        [label]: ['stars', 'reviewCount', 'price'].includes(
                          label,
                        )
                          ? event.target.value === ''
                            ? ''
                            : Number(event.target.value)
                          : event.target.value,
                      } as unknown as typeof defaultBox)
                    }
                  />
                )
              })}
              <Box>
                <Tooltip
                  bg={'#000000'}
                  label={`Add Retailes`}
                  placement="top-start"
                >
                  <IconButton
                    aria-label="button flexible"
                    icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                    onClick={
                      isShowInput
                        ? handleAddRetailer
                        : () => setIsShowInput(true)
                    }
                  />
                </Tooltip>
              </Box>
              {i !== 0 && (
                <Box>
                  <Tooltip
                    bg={'#000000'}
                    label={`Remove Retailes`}
                    placement="top-start"
                  >
                    <IconButton
                      aria-label="button flexible"
                      icon={<MinusIcon />}
                      onClick={() => handleRemoveBox(i)}
                    />
                  </Tooltip>
                </Box>
              )}
            </StyledWrapperContent>
          </React.Fragment>
        ))}
      </Flex>
      <StyledWrapperButton>
        <StyledButtonSubmit onClick={handleSubmit}>Submit</StyledButtonSubmit>
      </StyledWrapperButton>
    </StyledContainer>
  )
}

/**
 * SingleAdd component page.
 * @return {JSX.Element} SingleAdd component.
 */
export const SingleAdd = () => <SingleAddLayout {...useAddProductScreen()} />
