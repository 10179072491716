import { IconProps } from '@chakra-ui/react'

export type TTreeItem = {
  id: number
  label: string
  href?: string
  icon?: (props: IconProps) => JSX.Element
  children?: TTreeItem[]
  onClick?: () => void
  isTargetBlank?: boolean
  isLogout?: boolean
  title?: string
  idActive?: number
  setIdActive?: (id: number) => void
}
export const listItemSideBar: TTreeItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    href: '/',
  },
  {
    id: 2,
    label: 'Account Manager',
    children: [
      {
        id: 2.1,
        label: 'Users',
        href: '/users',
      },
      {
        id: 2.2,
        label: 'Administrators',
        href: '/administrators',
      },
    ],
  },
  {
    id: 3,
    label: 'Products',
    children: [
      {
        id: 3.1,
        label: 'View',
        href: '/product-list',
      },
      {
        id: 3.2,
        label: 'Add',
        href: '/add-products',
      },
      {
        id: 3.3,
        label: 'Requests',
        href: '/product-requests',
      },
    ],
  },
  {
    id: 4,
    label: 'Logic',
    children: [
      {
        id: 4.1,
        label: 'Routine Rules Alert',
        href: '/view-rules',
      },
      {
        id: 4.2,
        label: 'Rules',
        href: '/list-alert',
      },
    ],
  },
  {
    id: 5,
    label: 'Promotions',
    children: [
      {
        id: 5.1,
        label: 'Brands',
        href: '/promoted-brand',
      },
      {
        id: 5.2,
        label: 'Retailers',
        href: '/promoted-retailers',
      },
    ],
  },
  {
    id: 6,
    label: `App Content`,
    children: [
      {
        id: 6.1,
        label: 'Quiz Questions',
        href: '/quiz-questions',
      },
      {
        id: 6.2,
        label: 'Skin Profile Content',
        href: '/quiz-results',
      },
      {
        id: 6.3,
        label: 'Derm Tips',
        href: '/derm-tips',
      },
    ],
  },
  {
    id: 7,
    label: `Ingredients`,
    children: [
      {
        id: 7.1,
        label: 'Tags',
        href: '/ingredient-tags',
      },
      {
        id: 7.2,
        label: 'Dictionary',
        href: '/ingredient-dictionary',
      },
      {
        id: 7.3,
        label: 'INCI',
        href: '/ingredient-inci',
      },
      {
        id: 7.4,
        label: 'Definition',
        href: '/ingredient-definition',
      },
    ],
  },
  {
    id: 8,
    label: 'Analytics',
    children: [
      {
        id: 8.1,
        label: 'Individual',
        children: [
          {
            id: 8.11,
            label: 'User Metrics',
            href: '/individual-user-routine',
          },
          {
            id: 8.12,
            label: 'Product Views',
            href: '/product-external-link',
          },
        ],
      },
      {
        id: 8.2,
        label: 'Aggregate',
        children: [
          {
            id: 8.21,
            label: 'User Metrics',
            href: '/aggregate-user-routine',
          },
          {
            id: 8.22,
            label: 'Most Popular Products',
            href: '/most-popular-product',
          },
          {
            id: 8.23,
            label: 'Monthly User Use App',
            href: '/monthly-user-use-app',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    label: 'History Upload',
    href: '/history_upload',
  },
  {
    id: 10,
    label: 'Logout',
    href: '/login',
    isLogout: true,
  },
]
