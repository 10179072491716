export type Option = {
  value: string
  label: string
}
export const OptionSelectType: Option[] = [
  {
    label: 'PRODUCT_ALERT',
    value: '0',
  },
  {
    label: 'ROUTINE_ALERT',
    value: '1',
  },
]

export const OptionSelectStatus: Option[] = [
  {
    label: 'GREEN',
    value: '0',
  },
  {
    label: 'RED',
    value: '1',
  },
]
