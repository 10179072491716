import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useParams } from '../../../../../hooks/useRouter'
import { detailAlert, updateAlert } from '../../../../../service/viewRules'
import { Option } from '../../../../../types'

export type Errors = {
  [key: string]: {
    message: string
  }
}

export type TAlertDetail = {
  id: number
  alert: string
  buttonText: string
  tag: string
  possibleAttributes: string
  missAlert: string
  type: number | { value: string; label: string }
  status: number | { value: string; label: string }
  routine: boolean
  categoriesInclude: {
    catInclude1: { id: string; value: string; label: string }
    catInclude2: { id: string; value: string; label: string }
    catInclude3: { id: string; value: string; label: string }
  }[]
  categoriesExclude: {
    catExclude1: { id: string; value: string; label: string }
    catExclude2: { id: string; value: string; label: string }
    catExclude3: { id: string; value: string; label: string }
  }[]
  include:
    | {
        cat1Id: string
        cat1: string
        cat2Id: string
        cat2: string
        cat3Id: string
        cat3: string
      }[]
    | { cat1: string | null; cat2: string | null; cat3: string | null }[]
  excepts?:
    | {
        cat1Id: string
        cat1: string
        cat2Id: string
        cat2: string
        cat3Id: string
        cat3: string
      }[]
    | { cat1: string | null; cat2: string | null; cat3: string | null }[]
}

const initialState = {
  id: 0,
  alert: '',
  buttonText: '',
  tag: '',
  possibleAttributes: '',
  missAlert: '',
  routine: false,
  type: 0 || { value: '', label: '' },
  status: 0 || { value: '', label: '' },
  categoriesInclude: [
    {
      catInclude1: { id: '', value: '', label: '' },
      catInclude2: { id: '', value: '', label: '' },
      catInclude3: { id: '', value: '', label: '' },
    },
  ],
  categoriesExclude: [
    {
      catExclude1: { id: '', value: '', label: '' },
      catExclude2: { id: '', value: '', label: '' },
      catExclude3: { id: '', value: '', label: '' },
    },
  ],
  include: [
    {
      cat1Id: '',
      cat1: '',
      cat2Id: '',
      cat2: '',
      cat3Id: '',
      cat3: '',
    },
  ],
  excepts: [
    {
      cat1Id: '',
      cat1: '',
      cat2Id: '',
      cat2: '',
      cat3Id: '',
      cat3: '',
    },
  ],
}

export const useDetailAlert = () => {
  const [alertDetail, setAlertDetail] = useState<TAlertDetail>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { alertId } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [isShowInput, setIsShowInput] = useState(true)

  const handleChangeInput = useCallback((name: string, value?: string) => {
    setAlertDetail(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setErrors(prevState => ({
      ...prevState,
      [name]: { message: '' },
    }))
  }, [])

  const fetchDetailAlert = useCallback(async () => {
    if (!alertId) return
    setIsLoading(true)
    try {
      const detail = await detailAlert(alertId)
      const optionCategory = {
        ...detail,
        categoriesInclude: detail?.include?.map(item => ({
          catInclude1: {
            id: item.cat1Id,
            value: item.cat1,
            label: item.cat1,
          },
          catInclude2: {
            id: item.cat2Id,
            value: item.cat2,
            label: item.cat2,
          },
          catInclude3: {
            id: item.cat3Id,
            value: item.cat3,
            label: item.cat3,
          },
        })),
        categoriesExclude: detail?.excepts?.map(item => ({
          catExclude1: {
            id: item.cat1Id,
            value: item.cat1,
            label: item.cat1,
          },
          catExclude2: {
            id: item.cat2Id,
            value: item.cat2,
            label: item.cat2,
          },
          catExclude3: {
            id: item.cat3Id,
            value: item.cat3,
            label: item.cat3,
          },
        })),
      }
      setAlertDetail(optionCategory)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [alertId, toast])

  useEffect(() => {
    fetchDetailAlert()
  }, [fetchDetailAlert])

  const handleCancel = useCallback(() => navigate('/list-alert'), [navigate])
  const handleSubmit = useCallback(async () => {
    try {
      if (alertId) {
        const payload = {
          alert: alertDetail.alert,
          buttonText: alertDetail.buttonText,
          tag: alertDetail.tag,
          possibleAttributes: alertDetail.possibleAttributes,
          missAlert: alertDetail.missAlert,
          status: Number(
            (alertDetail.status as Record<string, string>)?.value ||
              alertDetail.status,
          ),
          type: Number(
            (alertDetail.type as Record<string, string>)?.value ||
              alertDetail.type,
          ),
          routine: alertDetail.routine,
          include: alertDetail.categoriesInclude?.map(item => ({
            cat1: item.catInclude1.value || null,
            cat2: item.catInclude2.value || null,
            cat3: item.catInclude3.value || null,
          })),
        } as TAlertDetail
        if (
          alertDetail.categoriesExclude &&
          Number((alertDetail.status as Record<string, string>)?.value === '1')
        ) {
          payload.excepts = alertDetail.categoriesExclude.map(item => ({
            cat1: item.catExclude1.value || null,
            cat2: item.catExclude2.value || null,
            cat3: item.catExclude3.value || null,
          }))
        }
        await updateAlert(alertId, payload)
      }

      toast({
        position: 'top-right',
        status: 'success',
        title: 'Change Success',
        duration: 1000,
      })
      navigate('/list-alert')
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [
    alertId,
    toast,
    navigate,
    alertDetail.alert,
    alertDetail.buttonText,
    alertDetail.tag,
    alertDetail.possibleAttributes,
    alertDetail.missAlert,
    alertDetail.status,
    alertDetail.type,
    alertDetail.routine,
    alertDetail.categoriesInclude,
    alertDetail.categoriesExclude,
  ])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (name: string, option: Option | unknown, indexItem?: number) => {
      if (typeof indexItem === 'number') {
        let currentValue = alertDetail[name].map((item, index) => {
          if (index === indexItem) {
            return option
          }
          return item
        })
        setAlertDetail(prevState => ({
          ...prevState,
          [name]: currentValue,
        }))
        return
      }
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setAlertDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [alertDetail],
  )

  const handleAddIncludeCategory = useCallback(() => {
    setAlertDetail(pre => ({
      ...pre,
      categoriesInclude: [
        ...(pre.categoriesInclude ?? []),
        {
          catInclude1: { id: '', value: '', label: '' },
          catInclude2: { id: '', value: '', label: '' },
          catInclude3: { id: '', value: '', label: '' },
        },
      ],
    }))
  }, [])

  const handleRemoveBox = useCallback(index => {
    setAlertDetail(prevState => ({
      ...prevState,
      categoriesInclude: prevState.categoriesInclude
        ? prevState.categoriesInclude.filter((_, idx) => idx !== index)
        : [],
    }))
  }, [])

  const handleAddExcludeCategory = useCallback(() => {
    setAlertDetail(pre => ({
      ...pre,
      categoriesExclude: [
        ...(pre.categoriesExclude ?? []),
        {
          catExclude1: { id: '', value: '', label: '' },
          catExclude2: { id: '', value: '', label: '' },
          catExclude3: { id: '', value: '', label: '' },
        },
      ],
    }))
  }, [])

  const handleRemoveBoxExclude = useCallback(index => {
    setAlertDetail(prevState => ({
      ...prevState,
      categoriesExclude: prevState.categoriesExclude
        ? prevState.categoriesExclude.filter((_, idx) => idx !== index)
        : [],
    }))
  }, [])

  const handleToggleRoutine = useCallback(() => {
    setAlertDetail({
      ...alertDetail,
      routine: !alertDetail.routine,
    })
  }, [alertDetail])

  return {
    handleChangeInput,
    errors,
    alertDetail,
    handleCancel,
    handleSubmit,
    isShowInput,
    setIsShowInput,
    handleAddIncludeCategory,
    handleOnChangeSelect,
    handleRemoveBox,
    isLoading,
    handleAddExcludeCategory,
    handleRemoveBoxExclude,
    handleToggleRoutine,
  }
}
